<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <search :search-data="searchData" />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <order-list
        :remove-data="removeData"
        :data-list="dataList"
        :fields="fields"
      />
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BPagination, BCardFooter,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Search from '@/views/Admin/Orders/elements/Search.vue'
import OrderList from '@/views/Admin/Orders/Index/OrderList.vue'

const tableName = 'orders'
export default {
  name: 'OrderPendings',
  components: {
    BRow,
    BCol,
    BCard,
    BPagination,
    BCardFooter,
    OrderList,
    Search,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'onumber',
          label: 'SİPARİŞ NO',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'odate',
          label: 'SİPARİŞ TARİHİ',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'order_status',
          label: 'DURUM',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-50 text-nowrap',
          tdClass: 'width-50 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          'customers.company AS company',
          'customer_accounts.name AS customer_account',
          'orders.odate AS odate',
          'orders.onumber AS onumber',
          'order_statuses.title AS order_status',
          'users.name AS username',
        ],
        where: {
          'orders.id_order_statuses': 1,
        },
        order_by: ['orders.id', 'DESC'],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['orders/dataList']
    },
    dataCounts() {
      return this.$store.getters['orders/dataCounts']
    },
    saveData() {
      return this.$store.getters['orders/dataSaveStatus']
    },
    search() {
      return this.$store.getters['orders/search']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.search.keyword) {
        this.dataQuery.or_like = {
          'orders.onumber': this.search.keyword,
          'customers.company': this.search.keyword,
          'customers.phone': this.search.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('orders/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('orders/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
