<template>
  <b-input-group>
    <b-form-input
      v-model="search.keyword"
      placeholder="Firma Adı..."
      @keydown.enter="searchData"
    />
    <b-input-group-append>
      <b-button
        variant="primary"
        @click="searchData"
      >
        <FeatherIcon icon="SearchIcon" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Search',
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    search() {
      return this.$store.getters['orders/search']
    },
  },
}
</script>
