<template>
  <b-table
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
  >
    <template #cell(onumber)="data">
      {{ data.item.onumber }}
    </template>
    <template #cell(company)="data">
      <div>
        {{ data.item.company }}
      </div>
      <div class="text-primary font-small-2">
        {{ data.item.customer_account }}
      </div>
    </template>
    <template #cell(odate)="data">
      {{ moment(data.item.odate).format('DD.MM.YYYY') }}
    </template>
    <template #cell(order_status)="data">
      {{ data.item.order_status }}
      <div class="font-small-2 text-primary">
        {{ data.item.username }}
      </div>
    </template>
    <template #cell(control)="data">
      <list-buttons
        primary-text="Görüntüle"
        :primary-action="'/app/orders/view/' + data.item.id"
        :delete-action="removeData"
        :data-id="data.item.id"
      />
    </template>
  </b-table>
</template>
<script>
import { BTable } from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'

export default {
  name: 'OrderList',
  components: {
    BTable,
    ListButtons,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    removeData: {
      type: Function,
      required: true,
    },
  },
}
</script>
